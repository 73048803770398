import React from 'react';

import { useCSVReader } from 'react-papaparse';

export default function CSVReader(props) {
  const { CSVReader } = useCSVReader();

  return (
    <CSVReader
      header={true}
      config={
        {
          header : true,
          skipEmptyLines : true
        }
      }
      onUploadAccepted={(results: any) => {
        console.log('---------------------------');
        console.log(results);
        console.log('---------------------------');
        if(props.callback !== undefined) {
          props.callback(results);
        }
      }}
    >
      {({
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps,
      }: any) => (
        <>
          <div>
            <button type='button' {...getRootProps()}>
              Browse file
            </button>
            <div>
              {acceptedFile && acceptedFile.name}
            </div>
            <button {...getRemoveFileProps()}>
              Remove
            </button>
          </div>
          <ProgressBar />
        </>
      )}
    </CSVReader>
  );
}