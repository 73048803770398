//import logo from './logo.svg';
import './App.css';
import React, {useState} from "react";
import Select from 'react-select';

import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator } from 'react-tabulator';

import ReadRemoteFile from './readRemoteFile.js';
import CSVReadderBasicUpload from './CSVReaderBasicUpload.tsx';

// ************************* FIREBASE SETUP START *************************

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";
import { getFirestore, collection, doc, setDoc, getDoc, addDoc, getDocs, query, where, orderBy, deleteDoc, updateDoc, arrayUnion, arrayRemove, deleteField } from "firebase/firestore"; 

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDTEUMuE2oR3Dg8bRtM1n4guAXEStPmvEA",
  authDomain: "trackstar-results.firebaseapp.com",
  projectId: "trackstar-results",
  storageBucket: "trackstar-results.appspot.com",
  messagingSenderId: "314644331188",
  appId: "1:314644331188:web:f797461701f446097cb5e1",
  measurementId: "G-GLLSZC47SW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);

// ************************* FIREBASE SETUP END *************************

const SESSIONS = ['QUALIFYING', 'PREFINAL', 'FINAL', 'FASTEST LAP'];

const CLASSES = [
  '4 CYCLE OPEN', 
  'JR 1 LO206',
  'JR 2 LO206',
  'SR LO206',
  'TAG CADET',
  'MICROMAX',
  'TAG JUNIOR',
  'TAG MASTERS',
  'TAG SENIOR',
  'SPORTSMAN'
];

var POSITIONS = [
  'DNS', 
  'DNF',
  'DQ',
];

for(let i = 0; i < 100; i++) {
  POSITIONS.push(i + 1);
}

const dropDownStyle = {
  control: base => ({
    ...base,
    border: 0,
    // This line disable the blue border
    boxShadow: 'none'
  })
};

function Box(props) {
  return(<div className="box">
      {props.content}
    </div>);
}

function CloseButton(props) {

  let classes = 'closeButton clickable';

  if(props.hidden === true) {
    classes += ' hidden';
  }

  return(<div className={classes} onClick={()=>{props.onRemove();}}>
    X
  </div>);
}

function CreateButton(props) {

  let text = 'CREATE';
  if(props.text !== undefined) {
    text = props.text;
  }

  return(
    <button className='createButton' onClick={() => {props.onClick();}}>
      {text}
    </button>
  );
}

class CreateRaceForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      'onCreate' : props.onCreate,
      'name' : '',
    }

    this.handleNameChange = this.handleNameChange.bind(this);
  }

  handleNameChange(event) {
    this.setState({name: event.target.value});
  }

  render() {
    return (
      <div className='newRaceContent'>
          <h3>
            ADD NEW RACE
          </h3>

          <form onSubmit={(e)=>{e.preventDefault()}}>
            <label>
              NAME:
              <input type="text" placeholder='<ENTER NAME>' value={this.state.name} onChange={this.handleNameChange} />
            </label>
          </form>
    
          <CreateButton onClick={()=>{
            this.state.onCreate(this.state.name);

            // Lets reset the forms state since we created a new race
            this.setState({
              'name' : '',
            });
          }}/>
          
      </div>);
  }
}

class CreateDriverForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      'onCreate' : props.onCreate,
      'name' : '',
    }

    this.handleNameChange = this.handleNameChange.bind(this);
  }

  handleNameChange(event) {
    this.setState({name: event.target.value});
  }

  render() {
    return (
      <div className='newRaceContent'>
          <h3>
            ADD NEW DRIVER
          </h3>

          <form onSubmit={(e)=>{e.preventDefault()}}>
            <label>
              NAME:
              <input type="text" placeholder='<ENTER NAME>' value={this.state.name} onChange={this.handleNameChange} />
            </label>
          </form>
    
          <CreateButton onClick={()=>{
            this.state.onCreate(this.state.name);

            // Lets reset the forms state since we created a new race
            this.setState({
              'name' : '',
            });
          }}/>
          
      </div>);
  }
}

class CreateSeriesForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      'onCreate' : props.onCreate,
      'name' : '',
      'pointsMappingID' : '',
      'pointsMappingData' : props.pointsMappingData
    }

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handlePointsMappingChange = this.handlePointsMappingChange.bind(this);
  }

  handleNameChange(event) {
    this.setState({name: event.target.value});
  }

  handlePointsMappingChange(event) {
    this.setState({pointsMappingID: event.target.value});
  }

  render() {

    const pointsMappingOptions = this.state.pointsMappingData.map((pointsMapping) => {
      return(
        <option key={pointsMapping.id} value={pointsMapping.id}>
        {pointsMapping.name}
        </option>
      );
    });

    return (
      <div className='newSeriesContent'>
          <h3>
            ADD NEW SERIES
          </h3>

          <form onSubmit={(e)=>{e.preventDefault()}}>
            <label>
              NAME:
              <input type="text" placeholder='<ENTER NAME>' value={this.state.name} onChange={this.handleNameChange} />
            </label>
            <br/>
            <label>
              SELECT POINTS MAPPING:
              <select value={this.state.pointsMappingID} onChange={this.handlePointsMappingChange}>
                <option value="" disabled>{'<SELECT DROPDOWN>'}</option>
                {pointsMappingOptions}
              </select>
            </label>
          </form>
    
          <CreateButton onClick={()=>{
            this.state.onCreate(this.state.name, this.state.pointsMappingID);

            // Lets reset the forms state since we created a new series
            this.setState({
              'name' : '',
              'pointsMappingID' : ''
            });
          }}/>
          
      </div>);
  }
}

class QueryResultsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      'onQueryChange' : props.onQueryChange,
      'seriesData' : props.seriesData,
      'selectedSeries' : {value: 'ALL', label: 'ALL'},
      'selectedClass' : {value: 'ALL', label: 'ALL'}
    }

    this.handleSelectedSeriesChange = this.handleSelectedSeriesChange.bind(this);
    this.handleSelectedClassChange = this.handleSelectedClassChange.bind(this);
  }

  handleSelectedSeriesChange(selection) {
    this.setState({selectedSeries: selection});
    this.state.onQueryChange(selection.value, this.state.selectedClass.value);
  }

  handleSelectedClassChange(selection) {
    this.setState({selectedClass: selection});
    this.state.onQueryChange(this.state.selectedSeries.value, selection.value);
  }

  render() {

    const seriesOptions = [{name : 'ALL'}].concat(this.state.seriesData).filter((series, index) => {
      if(series.name === 'ALL' || series.races.length > 0) {
        return true;
      }

      return false;
    }).map((series, index) => {
      return(
        {value: series.name, label: series.name}
      );
    });

    const classOptions = ['ALL'].concat(CLASSES).map((kartClass, index) => {
      return(
        {value: kartClass, label: kartClass}
      );
    });

    return (
      <div className='resultsQuery'>
          <h3>
            Select Series
          </h3>

          <Select 
              value={this.state.selectedSeries}
              className={'selectDropdown'}
              placeholder={'ALL'}
              options={seriesOptions}
              components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
              styles={dropDownStyle}
              onChange={this.handleSelectedSeriesChange} />

          <h3>
            Select Class
          </h3>

          <Select 
              value={this.state.selectedClass}
              className={'selectDropdown'}
              placeholder={'ALL'}
              options={classOptions}
              components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
              styles={dropDownStyle}
              onChange={this.handleSelectedClassChange} />
          
      </div>);
  }
}

class SignInForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      'onLogin' : props.onLogin,
      'email' : '',
      'password' : ''
    }

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  handleEmailChange(event) {
    this.setState({email: event.target.value});
  }

  handlePasswordChange(event) {
    this.setState({password: event.target.value});
  }

  render() {
    return (
      <div className='newRaceContent'>
          <h3>
            SIGN IN
          </h3>

          <form onSubmit={(e)=>{e.preventDefault()}}>
            <label>
              EMAIL:
              <input type="text" placeholder='<ENTER EMAIL>' value={this.state.email} onChange={this.handleEmailChange} />
            </label> 
              
            <br/>
            <label>
              PASSWORD:
              <input type="password" placeholder='<ENTER PASSWORD>' value={this.state.password} onChange={this.handlePasswordChange} />
            </label>
          </form>
    
          <CreateButton text={'LOGIN'} onClick={()=>{
            this.state.onLogin(this.state.email, this.state.password);

            // Lets reset the password form state since we tried logging in 
            this.setState({
              'password' : ''
            });
          }}/>
          
      </div>);
  }
}

class AddDriverResultsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      'raceName' : props.raceName,
      'raceID' : props.id,
      'driverData': props.driverData,
      'onCreate' : props.onCreate,
      'driverID' : null,
      'class' : null,
      'positionQualifying' : null,
      'positionPreFinal' : null,
      'positionFinal' : null,
      'fastestLap' : null,
    }

    this.handleDriverChange = this.handleDriverChange.bind(this);
    this.handleClassChange = this.handleClassChange.bind(this);
    this.handleQualifyingPositionChange = this.handleQualifyingPositionChange.bind(this);
    this.handlePreFinalPositionChange = this.handlePreFinalPositionChange.bind(this);
    this.handleFinalPositionChange = this.handleFinalPositionChange.bind(this);
    this.handleFastestLapChange = this.handleFastestLapChange.bind(this);
  }

  handleDriverChange(selected) {
    this.setState({driverID: selected});
  }

  handleClassChange(selected) {
    this.setState({class: selected});
  }

  handleQualifyingPositionChange(selected) {
    this.setState({positionQualifying: selected});
  }

  handlePreFinalPositionChange(selected) {
    this.setState({positionPreFinal: selected});
  }

  handleFinalPositionChange(selected) {
    this.setState({positionFinal: selected});
  }

  handleFastestLapChange(selected) {
    this.setState({fastestLap: selected});
  }

  render() {

    const driverOptions = this.state.driverData.map((driver) => {
      return(
        {value: driver.id, label: driver.name}
      );
    });

    const classOptions = CLASSES.map((kartClass, index) => {
      return(
        {value: kartClass, label: kartClass}
      );
    });

    const positionOptions = POSITIONS.map((position, index) => {
      return(
        {value: position, label: position}
      );
    });

    const fastestLapOptions = [
      {value: true, label: "<FASTEST LAP?> YES"},
      {value: false, label: "<FASTEST LAP?> NO"}
    ];

    return (
      <div className='driverResultForm'>

          <form onSubmit={(e)=>{e.preventDefault()}}>
            <label>
              DRIVER RESULT: <br/>
            </label>

            <Select 
              value={this.state.driverID}
              className={'selectDropdown'}
              placeholder={'<DRIVER>'}
              options={driverOptions}
              components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
              styles={dropDownStyle}
              onChange={this.handleDriverChange} />

            <Select 
              value={this.state.class}
              className={'selectDropdown'}
              placeholder={'<CLASS>'}
              options={classOptions}
              components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
              styles={dropDownStyle}
              onChange={this.handleClassChange} />

            <Select 
              value={this.state.positionQualifying}
              className={'selectDropdown'}
              placeholder={'<POSITION QUALIFYING>'}
              options={positionOptions}
              components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
              styles={dropDownStyle}
              onChange={this.handleQualifyingPositionChange} />

            <Select 
              value={this.state.positionPreFinal}
              className={'selectDropdown'}
              placeholder={'<POSITION PRE-FINAL>'}
              options={positionOptions}
              components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
              styles={dropDownStyle}
              onChange={this.handlePreFinalPositionChange} />
            
            <Select 
              value={this.state.positionFinal}
              className={'selectDropdown'}
              placeholder={'<POSITION FINAL>'}
              options={positionOptions}
              components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
              styles={dropDownStyle}
              onChange={this.handleFinalPositionChange} />

            <Select 
              value={this.state.fastestLap}
              className={'selectDropdown'}
              placeholder={'<FASTEST LAP?>'}
              options={fastestLapOptions}
              components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
              styles={dropDownStyle}
              onChange={this.handleFastestLapChange} />

          </form>

          <CreateButton text={'ADD'} onClick={()=>{
            this.state.onCreate(this.state.raceID, this.state.driverID.value, this.state.class.value, this.state.positionQualifying.value, this.state.positionPreFinal.value, this.state.positionFinal.value, this.state.fastestLap.value);

            // Lets reset the forms state since we created a new result
            this.setState({
              'driverID' : null,
              'positionQualifying' : null,
              'positionPreFinal' : null,
              'positionFinal' : null,
              'fastestLap' : {value: false, label: "<FASTEST LAP?> NO"},
            });
          }}/>
          
      </div>);
  }
}

function RaceResultsTable (props) {

  let columns = [
    { title: "NAME", field: "name", minWidth: 200, resizable: false },
  ];

  for(let sessionIndex in props.format) {
    let session = props.format[sessionIndex];
    
    columns.push({
      title : session,
      field : session,
      resizable: false,
      minWidth: 120,
      sorter:"number", sorterParams:{alignEmptyValues:"bottom",}
    });
  }

  let resultByClass = {}; 

  for(let raceClass in props.results) {
    let data = [];
    let classResults = {};

    for(let session in props.results[raceClass]) {
      for(let driverID in props.results[raceClass][session]) {
        let sessionResult = props.results[raceClass][session][driverID];
        let driver = props.driverData.find((x) => x.id === driverID);

        if(driver === undefined) {
          continue;
        }

        let driverName = driver.name;

        if(classResults[driverID] === undefined) {
          classResults[driverID] = {
            id : driverID,
            name : driverName,
            raceID : props.raceID,
            raceClass : raceClass
          };
        }

        if(session === 'FASTEST LAP') {
          if(parseInt(sessionResult) === 1 || sessionResult === true) {
            classResults[driverID][session] = 'YES';
          } else {
            classResults[driverID][session] = '';
          }
        } else {
          classResults[driverID][session] = sessionResult;
        }
      }
    }

    for(let driverID in classResults) {
      data.push(classResults[driverID]);
    }

    resultByClass[raceClass] = data;
  }

  let resultsTables = [];

  for(let raceClass in resultByClass) {
    let results = resultByClass[raceClass];

    resultsTables.push(
      <div className="raceResults" key={raceClass}>
        <h4>{raceClass}</h4>
        <ReactTabulator
          data={results}
          columns={columns}
          layout={"fitColumns"}
          initialSort={[
            {column:"QUALIFYING", dir:"asc"}, //then sort by this second
            {column:"PREFINAL", dir:"asc"}, //then sort by this second
            {column:"FINAL", dir:"asc"}, //sort by this first
          ]}
          events={{rowDblClick : function(e, row){
              let rowData = row.getData();
              props.onRemoveResult(rowData.raceID, rowData.id, rowData.raceClass);
          }}}
          selectable={false}
        />
      </div>
    );
  }

  return (<div className='raceResults'>
      <br/>
      <h4><b>RACE RESULTS</b></h4>
      <br/>   
      {resultsTables}

    </div>);

}

function RaceContent(props) {

  const [csvUploadSession, setCSVUploadSession] = useState(undefined);

  const seriesOptions = props.seriesData.map((series, index) => {
    if(!series.races.includes(props.id)) {
      return (                
        <option key={series.id} value={series.id}>{series.name}</option>
      );
    } else {
      return('');
    }
  });

  let inASeries = false;
  let inAllSeries = true;
  const selectedSeries = props.seriesData.map((series, index) => {
    if(series.races.includes(props.id)) {
      inASeries = true;

      if(props.user !== undefined) {
        return (     
          <span className="seriesRaceButton clickable" onClick={()=> {props.removeRaceFromSeries(series.id, props.id)}} key={series.name}><b>{series.name}</b><br/></span>
        );
      } else {
        return (     
          <span className="seriesRaceButton" key={series.id}><b>{series.name}</b><br/></span>
        );
      }

    } else {
      inAllSeries = false;
      return('');
    }
  });

  let seriesSelectionForm =         
  <form onSubmit={(e)=>{e.preventDefault()}}>
    <label>
      ADD TO SERIES:
      <select value={''} onChange={(e)=>{props.addRaceToSeries(e.target.value, props.id);}}>
        <option value="" disabled>{'<SELECT DROPDOWN>'}</option>
        {seriesOptions}
      </select>
    </label>
  </form>;

  if(inAllSeries || props.user === undefined) {
    // Disable displaying the form since we're in all series...
    seriesSelectionForm = '';
  }

  let selectedSeriesState = null;
  
  if(inASeries) {
    selectedSeriesState =     
    <h4>
      IN {selectedSeries}
    </h4>;
  } else {
    selectedSeriesState =     
    <h4>
      NOT IN ANY SERIES
    </h4>;
  }

  let addDriverResultsForm = <AddDriverResultsForm raceName={props.name} id={props.id} driverData={props.driverData} onCreate={props.onAddResult}/>

  const sessionOptions = SESSIONS.map((session, index) => {
    return(
      {value: session, label: session}
    );
  });
  
  let csvAddForm = 
  <>
    <Select 
      value={csvUploadSession}
      className={'selectDropdown'}
      placeholder={'SELECT'}
      options={sessionOptions}
      components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
      styles={dropDownStyle}
      onChange={(newValue)=>{
        setCSVUploadSession(newValue);
      }} />


    <CSVReadderBasicUpload callback={
      (results)=> {
        // Have dropdown with selectable sessions, use this dropdown to control which session data is being added
        let session = csvUploadSession === undefined ? '' : csvUploadSession.value;
        if(window.confirm('Are you sure you want to import this CSV data into ' + props.name + ' for session ' + session +  '?') === true) {
                    
          let classPositions = {}

          for(let classIndex in CLASSES) {
            let raceClass = CLASSES[classIndex];
            classPositions[raceClass] = 0;
          }
          
          for(let driverIndex in results.data) {
            let driver = results.data[driverIndex];

            let name = driver['Competitor'].trim().replace('  ', ' ');
            let raceClass = driver['Class']
              .toUpperCase()
              .replace('SR BRIGGS', 'SR LO206')
              .replace('JR 2 BRIGGS', 'JR 2 LO206');

            let driverData = props.driverData.find((x) => { return x.name.toUpperCase() === name.toUpperCase()});

            if(CLASSES.includes(raceClass)) {
              let position = ++classPositions[raceClass];

              if(driverData !== undefined) {
                props.addDriverSessionResult(props.id, driverData.id, raceClass, session, position);
              } else {
                window.alert('COULD NOT IMPORT: NO PROFILE FOR ' + name + '.');
              }
            } else {
              window.alert('COULD NOT IMPORT: INVALID CLASS ' + raceClass + '.');
            }
          } 
        }
      }
    }/>
  </>


  if(props.user === undefined) {
    addDriverResultsForm = undefined;
    csvAddForm = undefined;
  }

  return(<div className='raceContent'>

    <CloseButton hidden={props.user === undefined} onRemove={()=>{            
      props.onRemove(props.id);
    }}/>

    <h3>
      {props.name}
    </h3>
    {selectedSeriesState}

    {seriesSelectionForm}

    {csvAddForm}

    {addDriverResultsForm}

    <RaceResultsTable results={props.results} raceID={props.id} format={props.format} driverData={props.driverData} onRemoveResult={props.onRemoveResult}/>
  </div>);
}

function Races(props) {
  const racesItems = props.racesData;
  const racesList = racesItems.map((race) =>
    <Box key={race.name} value={race} content={
      <RaceContent user={props.user} id={race.id} name={race.name} results={race.results} format={race.format} driverData={props.driverData} seriesData={props.seriesData} addRaceToSeries={props.addRaceToSeries} removeRaceFromSeries={props.removeRaceFromSeries} onRemove={props.onRemove} onAddResult={props.onAddResult} onRemoveResult={props.onRemoveResult} addDriverSessionResult={props.addDriverSessionResult}/>
    }>
    </Box>
  );

  return(<div>
    {racesList}
  </div>);
}

function DriverContent(props) {

  return(<div className='driverContent'>

    <CloseButton hidden={props.user === undefined} onRemove={()=>{            
      props.onRemove(props.id);
    }}/>

    <h3>
      {props.name}
    </h3>

    <h4>
      {props.stats.wins} WINS <br/>
      {props.stats.fastest_laps} FASTEST LAPS <br/>
      {props.stats.podiums} PODIUMS <br/>
    </h4>

  </div>);
}

function Drivers(props) {
  const driversItems = props.driversData;
  const driversList = driversItems.map((driver) => {
    const stats = calculateDriverStats(driver, props.racesData);

    return(
      <Box key={driver.name} value={driver} content={
        <DriverContent user={props.user} id={driver.id} stats={stats} name={driver.name} onRemove={props.onRemove}/>
      }>
      </Box>
    );
  }
  );

  return(<div>
    {driversList}
  </div>);
}

function positionToPoints(points_mapping, format, position) {

  let format_points_map = points_mapping[format];

  if(position > 0 && position <= format_points_map.length) {
      // Subtract 1 because computers index at 0, while racing positions index at 1
      return format_points_map[position - 1];
  } else {
      return 0;
  }
}

function calculateSeriesPoints(series, racesData, pointsMappings) {

  let points_results = {
      'races' : {},
      'total' : {}
  };

  // series -> races -> results -> class -> format

  // Calculate points for each driver for each class for each race for each format
  for(let r = 0; r < series.races.length; r++) {
      let race = racesData.find((race) => race.id === series.races[r]);

      if(race === undefined) {
        continue;
      }

      let results = race.results;

      points_results['races'][race.name] = {};

      for(let c = 0; c < CLASSES.length; c++) {
          let class_title = CLASSES[c];
          let class_results = results[class_title];

          points_results['races'][race.name][class_title] = {};
          points_results['races'][race.name][class_title]['total'] = {};

          if(points_results['total'][class_title] === undefined) {
            points_results['total'][class_title] = {};
          }

          if(class_results === undefined) {
            continue;
          }

          for(let f = 0; f < race.format.length; f++) {
              let format_title = race.format[f];
              let format_results = class_results[format_title];

              points_results['races'][race.name][class_title][format_title] = {};

              for(let driver in format_results) {
                  let pointsMap = pointsMappings.find(x => x.id === series.pointsMappingID);
                  let points = positionToPoints(pointsMap, format_title, format_results[driver]);
                  points_results['races'][race.name][class_title][format_title][driver] = points;

                  if(!(driver in points_results['races'][race.name][class_title]['total'])) {
                      points_results['races'][race.name][class_title]['total'][driver] = 0;
                  }

                  if(!(driver in points_results['total'][class_title])) {
                      points_results['total'][class_title][driver] = 0;
                  }

                  points_results['races'][race.name][class_title]['total'][driver] += points;
                  points_results['total'][class_title][driver] += points;
              }
          }
      }
  }

  return points_results
}

function simplifyResults(points_results) {
  let simplified_results = {};

  // for each class
  // driver name, total points, total points for each race, each race break down

  for(let c = 0; c < CLASSES.length; c++) {
      let class_title = CLASSES[c];
      let class_results = points_results['total'][class_title];

      if (simplified_results[class_title] === undefined) {
        simplified_results[class_title] = [];
      }

      for(let driver in class_results) {

          let result_entry = {
              'name' : driver,
              'total' : class_results[driver]
          }

          // add each race, if no result for race, give zero
          for(let race in points_results['races']) {
              if(result_entry[race] === undefined) {
                result_entry[race] = {};
              }

              for(let format in points_results['races'][race][class_title]) {
                  if(driver in points_results['races'][race][class_title][format]) {
                      result_entry[race][format] = points_results['races'][race][class_title][format][driver];
                  } else {
                      result_entry[race][format] = 0;
                  }
              }
          }

          simplified_results[class_title].push(result_entry);
      }
  }

  // Sort each class by drivers total points
  for(let c = 0; c < CLASSES.length; c++) {
      simplified_results[CLASSES[c]] = simplified_results[CLASSES[c]].sort(function(a, b) {
          if(a['total'] > b['total']) {
              return -1;
          }

          if(a['total'] < b['total']) {
              return 1;
          }

          return 0;
      });
  }

  return simplified_results;
}

function positionToPositionString(position) {
  if(position === 1) {
    return '1st';
  }

  if(position === 2) {
    return '2nd';
  }

  if(position === 3) {
    return '3rd';
  }

  return position + 'th';
}

function Results(props) {
  let outputColumn = [];

  // For each series
  // For each driver
  // Perform calculations...

  props.seriesData.sort(function(a, b) {
    if(a.dateUpdated.seconds > b.dateUpdated.seconds) {
      return -1;
    }

    if(a.dateUpdated.seconds < b.dateUpdated.seconds) {
      return 1;
    }

    return 0;
  });

  for(let i = 0; i < props.seriesData.length; i++) {

    let targetSeries = props.seriesData[i];

    // SKIP IF SERIES HAS NO RACES AND NOT LOGGED IN
    if (targetSeries.races.length <= 0 && props.user === undefined) {
      continue;
    }

    // SKIP IF SERIES IS NOT IN SELECTED
    if(props.queryState.series !== 'ALL' && props.queryState.series !== targetSeries.name) {
      continue;
    }

    let results = calculateSeriesPoints(targetSeries, props.racesData, props.pointsMappingData);
    let simplified_results = simplifyResults(results);

    const pointsMapping = props.pointsMappingData.find(x => x.id === targetSeries.pointsMappingID);
    let pointsFormatKey = '(';

    for(let formatIndex in pointsMapping.order) {
      pointsFormatKey += pointsMapping.order[formatIndex] + ', ';
    }

    pointsFormatKey += ')';
    pointsFormatKey = pointsFormatKey.replace(', )', ')');

    let columns = [
      { title: "NAME", field: "name", minWidth: 200, resizable: false },
      { title: "POSITION", field: "position", minWidth: 120, resizable: false },
      { title: "TOTAL", field: "total", minWidth: 120, resizable: false, sorter:"number", sorterParams:{alignEmptyValues:"bottom",}},
    ];
  
    for(let raceIndex in targetSeries.races) {
      let raceID = targetSeries.races[raceIndex];
      let race = props.racesData.find((race) => race.id === raceID);
      
      if(race === undefined) {
        continue;
      }
 
      columns.push({
        title : race.name,
        field : race.name,
        resizable: false,
        minWidth: 280,
      });
    }

    let resultsTables = [];

    for(let raceClass in simplified_results) {
      if(simplified_results[raceClass].length > 0) {

        // SKIP IF CLASS IS NOT IN SELECTED
        if(props.queryState.className !== 'ALL' && props.queryState.className !== raceClass) {
          continue;
        }

        let data = [];

        let positionIndex = 0;
        for(let resultIndex in simplified_results[raceClass]) {
          let result = simplified_results[raceClass][resultIndex];
          let driver = props.driversData.find((x) => x.id === result.name);
          let position = positionIndex + 1;
          let positionText = positionToPositionString(position);
          
          if(driver === undefined) {
            continue;
          }

          positionIndex++;

          let resultData = {
            name : driver.name,
            total : result.total,
            id : resultIndex,
            position: positionText
          };

          if(driver.notVIKAMember !== undefined && driver.notVIKAMember === true) {
            resultData['name'] += ' *';
          }

          for(let race in result) {

            if(race === 'name' || race === 'total') {
              continue;
            }

            let raceResult = result[race];
            let output = '(';

            for(let session in raceResult) {

              let sessionResult = raceResult[session];

              if(session === 'name' || session === 'total' || (session === 'FASTEST LAP' && parseInt(sessionResult) <= 0)) {
                continue;
              }

              output += sessionResult + ', ';
            }

            output += ')';

            resultData[race] = output.replace(', )', ')');
          }

          data.push(resultData);
        }

        resultsTables.push(
          <div className="seriesResultsContent" key={raceClass}>
            <h4>{raceClass}</h4>
            <ReactTabulator
              data={data}
              columns={columns}
              layout={"fitColumns"}
              initialSort={[
                {column:"TOTAL", dir:"asc"}, //sort by this first
              ]}
              selectable={false}
              textSize={'20px'}
            />
          </div>
        );
      }
    }

    const seriesStats = calculateSeriesStats(targetSeries, props.racesData);

    outputColumn.push(
      <Box key={targetSeries.name} content={
        <div className='seriesResultsContent'>

          <CloseButton hidden={props.user === undefined} onRemove={()=>{            
            props.onRemove(targetSeries.id);
          }}/>

          <h3>{targetSeries.name}</h3>

          <h4>
            {seriesStats.numRacers} RACERS <br/>
            {seriesStats.numClasses} CLASSES <br/>
            {seriesStats.racesComplete} RACES COMPLETE <br/>
          </h4>

          <br/>

          <h4>
            RESULTS KEY: <br/>
            {pointsFormatKey} <br/>
          </h4>

          <br/>

          {resultsTables}

          <h4>{targetSeries.notes}</h4>

        </div>
      }>
      </Box>
    );
  }


  return(<div>
    {outputColumn}
  </div>);
}

function calculateSeriesStats(series, racesData) {

  // Calculate number of drivers

  let stats = {
    numClasses : undefined,
    numRacers: undefined,
    racesComplete: undefined
  }

  let driverIDs = [];
  let classes = [];

  for(let raceIndex in series['races']) {
    let raceID = series['races'][raceIndex];
    let race = racesData.find((race) => race.id === raceID);
    
    if(race !== undefined) {
      for(let raceClass in race.results) {

        if(!classes.includes(raceClass)) {
          classes.push(raceClass);
        }

        for(let session in race.results[raceClass]) {
          for(let driverID in race.results[raceClass][session]) {
            if(!driverIDs.includes(driverID)) {
              driverIDs.push(driverID);
            }
          }
        }
      }
    }
  }

  stats['numRacers'] = driverIDs.length;
  stats['numClasses'] = classes.length;
  stats['racesComplete'] = series.races.length;

  return stats;
}

function calculateDriverStats(driver, racesData) {

  let stats = {
    wins : 0,
    fastest_laps : 0,
    podiums : 0
  };

  for(let raceID in racesData) {
    let race = racesData[raceID];

    for(let raceClass in race['results']) {
      for(let session in race['results'][raceClass]) {
        let classResults = race['results'][raceClass][session];
        for(let driverID in classResults) {
          let result = parseInt(classResults[driverID]);

          if(session === 'FINAL' && result === 1 && driverID === driver.id) {
            stats['wins'] += 1
          }

          if(session === 'FINAL' && result <= 3 && result >= 1 && driverID === driver.id) {
            stats['podiums'] += 1
          }

          if(session === 'FASTEST LAP' && (result === true || result === 1) && driverID === driver.id) {
            stats['fastest_laps'] += 1
          }
        }
      } 
    }
  }

  return stats;
}

function NavBar(props) {

  const navList = props.panels.map((panel) => {
    return(
      <div key={panel} onClick={()=>{props.onClick(panel);}} className={panel === props.selectedPanel ? 'selected' : ''}>
      {panel}
    </div>
    );
  });

  return(
    <div className="navBar">
        {navList}
    </div>
  );
}

function LoginStatus(props) {

  let classes = "login";

  if(props.selectedPanel === 'login') {
    classes += ' selected'; 
  }

  return(
    <div className={classes}
    onClick={()=>{props.onClick('login');}}>
    Sign in
    </div>
  );
}

function signUserIn(email, password) {
  signInWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode, errorMessage);
  }); 
}

function signUserOut() {
  signOut(auth).then(() => {
    // Sign-out successful.
  }).catch((error) => {
    // An error happened.
    console.log(error);
  });
}

class App extends React.Component {

  constructor(props) {
    super(props);

    let formatNames = ['QUALIFYING', 'PREFINAL', 'FINAL', 'FASTEST LAP'];

    this.state = {
        selectedPanel: 'series',
        series : [],
        races : [],
        drivers : [],
        pointsMappings : [],
        queryState : {
          series : 'ALL',
          className : 'ALL'
        },
        user : undefined
    };

    this.changePanel = this.changePanel.bind(this);

    this.addPointsMapping = this.addPointsMapping.bind(this);

    this.removeSeries = this.removeSeries.bind(this);
    this.addSeries = this.addSeries.bind(this);
    
    this.removeRace = this.removeRace.bind(this);
    this.addRace = this.addRace.bind(this);
    
    this.removeRaceFromSeries = this.removeRaceFromSeries.bind(this);
    this.addRaceToSeries = this.addRaceToSeries.bind(this);

    this.removeDriver = this.removeDriver.bind(this);
    this.addDriver = this.addDriver.bind(this);
    this.addDriverResult = this.addDriverResult.bind(this);
    this.removeDriverResult = this.removeDriverResult.bind(this);

    this.loadEntireState = this.loadEntireState.bind(this);
    this.loadSeries = this.loadSeries.bind(this);
    this.loadRaces = this.loadRaces.bind(this);
    this.loadDrivers = this.loadDrivers.bind(this);
    this.loadPointsMappings = this.loadPointsMappings.bind(this);
  }

  componentDidMount() {
    // Load firebase items?
    console.log('app mounted');
    this.loadEntireState();

    onAuthStateChanged(auth, (user) => {
      if (user) {
        // https://firebase.google.com/docs/reference/js/firebase.User

        this.setState( {
          'user' : user
        });

        console.log('Signed in', user);

        /*
        let formatNames = ['QUALIFYING', 'PREFINAL', 'FINAL', 'FASTEST LAP'];
        //name, format_names, format_points
        this.addPointsMapping(
          "VIKA 2023 Winter Series",
          formatNames,
          [
            [100, 85, 75, 65, 55, 50, 45, 40, 35, 30, 25, 20, 15, 10, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, ].map(function(x) { return x + 300; }), 
            [200, 175, 155, 140, 130, 120, 110, 100, 90, 80, 75, 70, 65, 60, 55, 50, 45, 40, 35, 30, 25, 20, 15, 10, 5, 5, 5, 5, 5, 5, 5, ].map(function(x) { return x + 300; }), 
            [300, 250, 210, 185, 150, 130, 120, 110, 100, 90, 80, 75, 70, 65, 60, 55, 50, 45, 40, 35, 30, 25, 20, 15, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10].map(function(x) { return x + 300; }), 
            [0]
          ]
        );
        */

      } else {
        this.setState( {
          user : undefined
        });

        console.log('Signed out');
      }
    });
  }

  async loadEntireState() {
    this.loadPointsMappings();
    this.loadSeries();
    this.loadRaces();
    this.loadDrivers();
  }

  async loadPointsMappings() {

    let state = {
      pointsMappings : [],
    };

    // Load points mappings
    const pointsMappingQuerySnapshot = await getDocs(collection(db, "points_mappings"));
    pointsMappingQuerySnapshot.forEach((doc) => {
      state['pointsMappings'].push({id : doc.id, ...doc.data()});
    });

    console.log(state);
    this.setState(state);
  }

  async loadSeries() {

    let state = {
      series : []
    };

    // Load series
    const seriesQuerySnapshot = await getDocs(collection(db, "series"));
    seriesQuerySnapshot.forEach((doc) => {
      state['series'].push({
        id : doc.id,
        ...doc.data()
      });
    });

    console.log(state);
    this.setState(state);
  }

  async loadRaces() {

    let state = {
      races : [],
    };

    // Load races
    const racesQuerySnapshot = await getDocs(collection(db, "races"));
    racesQuerySnapshot.forEach((doc) => {
      state['races'].push({id : doc.id, ...doc.data()});
    });

    console.log(state);
    this.setState(state);
  }

  async loadDrivers() {

    let state = {
      drivers : [],
    };

    // Load drivers
    const q = query(collection(db, "drivers"), orderBy("name", 'asc'));

    const driversQuerySnapshot = await getDocs(q);
    driversQuerySnapshot.forEach((doc) => {
      state['drivers'].push({id : doc.id, ...doc.data()});
    });

    console.log(state);
    this.setState(state);
  }

  changePanel(panelName) {
    this.setState({
      selectedPanel : panelName
    });
  }

  addPointsMapping(name, format_names, format_points) {
    let mapping = {
        'name' : name,
        'order' : format_names
    };
  
    // Check that we have the same amount of format names and format point items
    if(format_names.length !== format_points.length) {
        alert.log('Fromat names does not match points format length');
        return null;
    }
  
    for(let i = 0; i < format_names.length; i++) {
        let name = format_names[i];
        let points = format_points[i];
  
        mapping[name] = points;
    }
  
    return addDoc(collection(db, "points_mappings"), mapping).then(
      this.loadPointsMappings()
    );
  }

  removeSeries(seriesID) {

    if(window.confirm('Are you sure you want to DELETE ' + seriesID + '?') === true) {
      // Remove series
      return deleteDoc(doc(db, "series", seriesID)).then(
        this.loadSeries()
      );
    }
  }

  addSeries(seriesName, pointsMappingID) {

    if(this.state.series.find((x) => x.name === seriesName) === undefined && seriesName !== '' && pointsMappingID !== '') {
      return addDoc(collection(db, "series"), {
        'name' : seriesName,
        'dateUpdated' : new Date(),
        'pointsMappingID' : pointsMappingID,
        'races' : [], 
      }).then(
        this.loadSeries()
      );
    }
  }

  removeRace(raceID) {

    if(window.confirm('Are you sure you want to DELETE ' + raceID + ' and all related results data?') === true) {
      // Remove race

      // TODO REMOVE RACE FROM SERIES
      return deleteDoc(doc(db, "races", raceID)).then(
        this.loadRaces()
      );
    }
  }

  addRace(raceName) {
    if(this.state.races.find((x) => x.name === raceName) === undefined && raceName !== '') {
      return addDoc(collection(db, "races"), {
        name : raceName,
        results : {},
        format : ['QUALIFYING', 'PREFINAL', 'FINAL', 'FASTEST LAP'],
        dateUpdated : new Date()
      }).then(
        this.loadRaces()
      );
    }
  }

  removeRaceFromSeries(seriesID, raceID) {
    if(window.confirm('Are you sure you want to REMOVE ' + raceID + ' from ' + seriesID + '?') === true) {
      const seriesRef = doc(db, "series", seriesID);

      return updateDoc(seriesRef, {
          races: arrayRemove(raceID)
      }).then(() => {
        this.loadSeries();
      });   
    }
  }

  addRaceToSeries(seriesID, raceID) {
    const seriesRef = doc(db, "series", seriesID);

    return updateDoc(seriesRef, {
        races: arrayUnion(raceID)
    }).then(() => {
      this.loadSeries();
    });   
  }

  removeDriver(driverID) {
    if(window.confirm('Are you sure you want to DELETE ' + driverID + ' and all related results data?') === true) {
      // Remove driver

      // TODO REMOVE DRIVER FROM RACES
      return deleteDoc(doc(db, "drivers", driverID)).then(
        this.loadDrivers()
      );
    }
  }

  addDriver(driverName) {

    if(this.state.drivers.find((x) => x.name === driverName) === undefined && driverName !== '') {
      return addDoc(collection(db, "drivers"), {
        name : driverName,
        dateUpdated : new Date()
      }).then(
        this.loadDrivers()
      );
    }
  }

  addDriverResult(raceID, driverID, kartClass, positionQualifying, positionPreFinal, positionFinal, fastestLap) {

    if (raceID !== '' && driverID !== '') {

      const raceDocRef = doc(db, "races", raceID);

      let qualiKey = "results." + kartClass + ".QUALIFYING." + driverID;
      let preFinalKey = "results." + kartClass + ".PREFINAL." + driverID;
      let finalKey = "results." + kartClass + ".FINAL." + driverID;
      let fastestLapKey = "results." + kartClass + ".FASTEST LAP." + driverID;

      return updateDoc(raceDocRef, {
        [qualiKey] : positionQualifying,
        [preFinalKey] : positionPreFinal,
        [finalKey] : positionFinal,
        [fastestLapKey] : fastestLap
      }).then(
        this.loadRaces()
      );
    }
  }

  addDriverSessionResult(raceID, driverID, kartClass, session, sessionPosition) {

    if (raceID !== '' && driverID !== '' && session !== '') {

      const raceDocRef = doc(db, "races", raceID);

      let sessionKey = "results." + kartClass + "." + session + "." + driverID;

      return updateDoc(raceDocRef, {
        [sessionKey] : sessionPosition,
      });
    }
  }

  removeDriverResult(raceID, driverID, kartClass) {

    if(window.confirm('Are you sure you want to DELETE ' + driverID + '\'s data from race ' + raceID + '?') === true) {
      if (raceID !== '' && driverID !== '') {

        const raceDocRef = doc(db, "races", raceID);
  
        let qualiKey = "results." + kartClass + ".QUALIFYING." + driverID;
        let preFinalKey = "results." + kartClass + ".PREFINAL." + driverID;
        let finalKey = "results." + kartClass + ".FINAL." + driverID;
        let fastestLapKey = "results." + kartClass + ".FASTEST LAP." + driverID;
  
        return updateDoc(raceDocRef, {
          [qualiKey] : deleteField(),
          [preFinalKey] : deleteField(),
          [finalKey] : deleteField(),
          [fastestLapKey] : deleteField()
        }).then(
          this.loadRaces()
        );
      }
    }
  }

  render() {
    // Write logic here

    let appContent = null;

    if(this.state.selectedPanel === 'series') {
      if(this.state.user !== undefined) {
        appContent =         
        <div className="mainSplit">
          <div className='full'>
            <Box content={
                  <CreateSeriesForm onCreate={this.addSeries} pointsMappingData={this.state.pointsMappings}/>
                }/>
            <Box content={
              <QueryResultsForm seriesData={this.state.series} onQueryChange={(seriesName, className) => {
                this.setState({
                  'queryState' : {
                    'series' : seriesName,
                    'className' : className
                  }
                });
              }}/>
            }/>
  
            <Results user={this.state.user} queryState={this.state.queryState} seriesData={this.state.series} racesData={this.state.races} pointsMappingData={this.state.pointsMappings} driversData={this.state.drivers} onRemove={this.removeSeries}/>
          </div>
        </div>;
      } else {
        appContent =         
        <div className="mainSplit">
          <div className='full'>
            <Box content={
              <QueryResultsForm seriesData={this.state.series} onQueryChange={(seriesName, className) => {
                this.setState({
                  'queryState' : {
                    'series' : seriesName,
                    'className' : className
                  }
                });
              }}/>
            }/>
  
            <Results queryState={this.state.queryState} seriesData={this.state.series} racesData={this.state.races} pointsMappingData={this.state.pointsMappings} driversData={this.state.drivers}/>
          </div>
        </div>;  
      }
    }

    if(this.state.selectedPanel === 'races') {
      if(this.state.user !== undefined) {
        appContent =         
        <div className="mainSplit">
          <div className='full'>
            <Box content={
                <CreateRaceForm onCreate={this.addRace}/>
              }/>

            <Races user={this.state.user} racesData={this.state.races} driverData={this.state.drivers} seriesData={this.state.series} addRaceToSeries={this.addRaceToSeries} removeRaceFromSeries={this.removeRaceFromSeries} onRemove={this.removeRace} onAddResult={this.addDriverResult} addDriverSessionResult={this.addDriverSessionResult} onRemoveResult={this.removeDriverResult}/>
          </div>
        </div>;
      } else {
        appContent =         
        <div className="mainSplit">
          <div className='full'>
            <Races user={this.state.user} racesData={this.state.races} driverData={this.state.drivers} seriesData={this.state.series} addRaceToSeries={this.addRaceToSeries} removeRaceFromSeries={this.removeRaceFromSeries} onRemove={this.removeRace} onAddResult={this.addDriverResult} addDriverSessionResult={this.addDriverSessionResult} onRemoveResult={()=>{}}/>
          </div>
        </div>;
      }
    }

    if(this.state.selectedPanel === 'drivers') {
      if(this.state.user !== undefined) {
        appContent =         
        <div className="mainSplit">
          <div className='full'>
            <Box content={
                <CreateDriverForm onCreate={this.addDriver}/>
            }/>
            <Drivers user={this.state.user} driversData={this.state.drivers} racesData={this.state.races} onRemove={this.removeDriver}/>
          </div>
        </div>;
      } else {
        appContent =         
        <div className="mainSplit">
          <div className='full'>
            <Drivers user={this.state.user} driversData={this.state.drivers} racesData={this.state.races} onRemove={this.removeDriver}/>
          </div>
        </div>;
      }
    }

    if(this.state.selectedPanel === 'login') {

      let subContent = undefined;
      if(this.state.user === undefined) {
        subContent = <SignInForm onLogin={signUserIn}/>
      } else {
        subContent = <div className='signInContent'>
          <h3>Hi, {this.state.user.email}</h3>
          <CreateButton onClick={signUserOut} text={'LOGOUT'}/>
        </div>         
      }

      appContent =         
      <div className="mainSplit">
        <div className='full'>
          <Box content={subContent}/>
        </div>
      </div>;
    }

    // return JSX
    return (
      <div className="app">
        <div className="contentHolder">
          <div className="title-row">
            <h1>
            <div>Track Star </div>
            <svg width="63" height="59" viewBox="0 0 63 59" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M31.5 0L38.7967 22.4569H62.4093L43.3063 36.3361L50.603 58.7931L31.5 44.9139L12.397 58.7931L19.6937 36.3361L0.590664 22.4569H24.2033L31.5 0Z" fill="#FF0000"/>
            </svg>
            </h1>
            <LoginStatus onClick={this.changePanel} selectedPanel={this.state.selectedPanel}/>

          </div>
          
          <NavBar onClick={this.changePanel} panels={['series', 'races', 'drivers']} selectedPanel={this.state.selectedPanel} />
          {appContent}
        </div>


        <footer>
          <a href="https://mnursey.github.io/" target="_blank">Mitchell Nursey 2023 ©</a> <br/> 
          All rights reserved.
        </footer>

      </div>
    );
  }
}

export default App;
